import {
    request
} from 'request/request.js'

//获取企业列表
export function getMerchantList(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMerchantList',
        data: data
    })
}
//获取企业的成员信息
export function getMerchantAccountList(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMerchantAccountList',
        data: data
    })
}

//根据企业id获取企业下面的小程序
export function getMiniProListByMerchantId(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMiniProListByMerchantId',
        data: data
    })
}
//删除企业下面的小程序
export function deleteMiniPro(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/deleteMiniPro',
        data: data
    })
}

//获取企业信息
export function getMerchantByMerchantId(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMerchantByMerchantId',
        data: data
    })
}
//获取企业的订单列表
export function getTemplateOrderList(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMerchantMiniTemplateOrderList',
        data: data
    })
}

//获取企业的订单列表
export function getMerchantOrderInfo(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMerchantOrderInfo',
        data: data
    })
}

// 获取客户列表
export function getAccountList(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getAccountList',
        data: data
    })
}

// 获取用户信息通过用户id
export function getAccountInfoById(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMerchantAccountInfoById',
        data: data
    })
}

//根据用户id获取企业下面的小程序
export function getMiniProListByAccountId(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMiniProListByAccountId',
        data: data
    })
}

//获取客户加入的企业
export function getMerchantListByAccountId(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMerchantListByAccountId',
        data: data
    })
}

//获取小程序列表
export function getMiniProPage(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMiniProPage',
        data: data
    })
}

//获取商户下拉
export function getMerchantOption(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMerchantOption',
        data: data
    })
}

//获取模板信息下拉
export function getTemplateInfoOption(data) {
    return request({
        method: 'post',
        url: '/template/admin/getTemplateInfoOption',
        data: data
    })
}

//创建不要支付的订单
export function createTempOrderNoPayment(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/createTempOrderNoPayment',
        data: data
    })
}
//获取某个企业的成员信息
export function getMerchantAccountOption(data) {
    return request({
        method: 'post',
        url: '/merchant/admin/getMerchantAccountOption',
        data: data
    })
}



//查询单挑优惠券信息
export function queryPage(data) {
    return request({
        method: 'post',
        url: '/consumer/minipro/customer/queryClientPage',
        data: data
    })
}

export function findAllChannel(data) {
    return request({
        method: 'post',
        url: '/consumer/minipro/channel/findAll',
        data: data
    })
}

export function findChannelStatisticData(data) {
    return request({
        method: 'post',
        url: '/consumer/minipro/channel/getStatisticData',
        data: data
    })
}

export function filterStatisticData(data) {
    return request({
        method: 'post',
        url: '/consumer/minipro/channel/filterStatisticData',
        data: data
    })
}

export function queryClientById(data) {
    return request({
        method: 'post',
        url: '/consumer/minipro/customer/queryClientById',
        data: data
    })
}

export function selectCouponhistory(data) {
    return request({
        method: 'post',
        url: '/mall/minipro/couponHistory/selectCouponhistory',
        data: data
    })
}

//获取微信代码模板列表
export function getNormalTemplateList(data) {
    return request({
        method: 'post',
        url: '/wechat/admin/getNormalTemplateList',
        data: data
    })
}

//获取微信代码草稿模板列表
export function getTemplateDraftList(data) {
    return request({
        method: 'post',
        url: '/wechat/admin/getTemplateDraftList',
        data: data
    })
}


//发布新的微信代码模板
export function releaseWeChatCodeTemplate(data) {
    return request({
        method: 'post',
        url: '/wechat/admin/releaseWeChatCodeTemplate',
        data: data
    })
}


//将微信草稿模板添加到代码模板库
export function addToTemplate(data) {
    return request({
        method: 'post',
        url: '/wechat/admin/addToTemplate',
        data: data
    })
}