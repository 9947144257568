import config from "../config/index"
/**
 * 通过素材url获取图片的尺寸
 * 如果解析失败，会返回[1,1] 如果返回0，则页面解析错误
 * @param url eg:caozanpro/20210804/610a0d72e4b08ff444a42ab5_w_213_h_213.png
 * @returns {number[]}
 */
export const getMaterialSize = (url)=>{
  let w = 1,h = 1;
  //地址为空
  if (!url || url.length === 0) return [w,h];

  let pointIndex = url.indexOf('.');

  //地址没有后缀
  if (pointIndex === -1) return [w,h];

  url = url.substring(0,pointIndex);
  let splits = url.split(/_w_|_h_|\./);
  if (splits.length > 0){w = +splits[1];}
  if (splits.length > 1){h = +splits[2];}

  return [w,h];
};

/**
 * 通过分隔符拼接的url 获取第一个地址
 * @param urls
 */
export const splitFirstImage = (urls,split)=>{
  split = split || '|';
  if (urls && urls.length > 0){
    return urls.split(split)[0]
  }
  return '';
};

/**
 * 兼容http和base64
 */
export const getImageUrl = (url)=>{
  if (!url) return '';
  if (url.startsWith("data:")) return url;
  if (url.startsWith("http")) return url;
  return `${config.imageUrl}${url}`;
};
