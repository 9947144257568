<template>
    <div>
        <div class="table_box">
            <el-row align="middle" type="flex"
                    style="background: white;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 20px">
                <el-col :span="5">
                    <div class="keyword">
                        <span>{{$t("m.customer.guanjiangci")}}：</span>
                        <el-input v-model="keywords" :placeholder="$t('m.customer.qingshuruguanjianci')" style="height: 40px;"></el-input>
                    </div>
                </el-col>
                <el-col :span="10" :offset="1">
                    <div class="keyword">
                        <span>{{$t("m.customer.zhuceshijian")}}：</span>
                        <el-date-picker
                                v-model="time"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                align="right"
                                unlink-panels
                                :range-separator="$t('m.pubilc.zhi')"
                                :start-placeholder="$t('m.customer.kaishishijian')"
                                :end-placeholder="$t('m.customer.jieshushijiang')">
                        </el-date-picker>
                    </div>
                </el-col>
                <el-col :span="5" align="end">
                    <el-button size="small" @click="search_keywords">{{$t("m.pubilc.sousuo")}}</el-button>
                    <el-button type="primary" size="small" @click="addmini">{{$t("m.pubilc.tinajia")}}</el-button>
                </el-col>
            </el-row>
            <el-row class="eltable">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column :label="$t('m.customer.xiaochengxu')" width="250px">
                        <template slot-scope="scope">
                            <div style="display: flex">
                                <img v-if="scope.row.logoUrl" :src="imageUrl(scope.row.logoUrl)" class="photoUrl">
                                <div v-else class="photoUrl">
                                    <img src="../../../assets/edit-imgs/making/default-icon.png" />
                                </div>
                                <div>
                                    <div>{{scope.row.name}}</div>
                                    <router-link class="preview"
                                                 :to="{path:'/manager/preview',query:{id:scope.row.id}}"
                                                 target="_blank">{{scope.row.id}}</router-link>
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('m.customer.erweima')" width="100px">
                        <template slot-scope="scope">
                            <el-image v-if="scope.row.wechatMiniProCode" :src="imageUrl(scope.row.wechatMiniProCode)"
                                      class="photoUrl" :preview-src-list="[imageUrl(scope.row.wechatMiniProCode)]"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('m.customer.fukuanxinxi')">
                        <template slot-scope="scope">
                            <div>{{scope.row.templateName}}</div>
                            <div class="tips">{{scope.row.orderDesc}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createAccountName" :label="$t('m.customer.createder')">
                    </el-table-column>
                    <el-table-column prop="createTime" :label="$t('m.customer.zhuceshijian')">
                    </el-table-column>
                    <el-table-column prop="merchantName" :label="$t('m.customer.guishu')">
                    </el-table-column>
                    <el-table-column prop="wechatReleaseTime" :label="$t('m.customer.suihoufabu')">
                    </el-table-column>
                    <el-table-column prop="version" :label="$t('m.customer.zuihoubanben')" align="center">
                    </el-table-column>
                </el-table>
            </el-row>
            <el-row type="flex" style="justify-content: flex-end;margin: 15px 0 0;">
                <el-pagination class="pagination" background layout="total,prev, pager, next" :total="total"
                               @current-change='current_change' style="margin-top: 10px">
                </el-pagination>
            </el-row>
        </div>
        <el-dialog title="新增小程序" :visible="addVisible" width="500px" @close="close()">
            <el-form label-width="120px" class="formContent">
                <el-form-item>
                    <span slot="label"><span style="color: red;margin-right: 5px;">*</span>小程序名称:</span>
                    <el-input v-model="minipro.miniProName" placeholder="请输入小程序名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <span slot="label"><span style="color: red;margin-right: 5px;">*</span>企业/组织:</span>
                    <el-select v-model="minipro.merchantId" filterable placeholder="请选择企业/组织" @change="merchantChange">
                        <el-option
                                v-for="item in merchantList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <span slot="label"><span style="color: red;margin-right: 5px;">*</span>创建者:</span>
                    <el-select v-model="minipro.userid" placeholder="请选择创建者" filterable>
                        <el-option
                                v-for="item in userList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <span slot="label"><span style="color: red;margin-right: 5px;">*</span>模板:</span>
                    <el-select v-model="minipro.priceId">
                        <el-option
                                v-for="item in skuList"
                                :key="item.priceId"
                                placeholder="请选择模板"
                                :label="item.templateName+'（'+item.versionName+'，'+item.durationName+'）'"
                                :value="item.priceId">
                            <span>{{ item.templateName }}（{{item.versionName}}，{{item.durationName}}）</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="close">取消</el-button>
                    <el-button type="primary" :loading="loading" @click="createdTemplate">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>

<script>
    import {getMiniProPage,getMerchantOption,createTempOrderNoPayment,getMerchantAccountOption,
        getTemplateInfoOption} from 'request/api/client_list.js';
    import {getImageUrl} from "../../../utils/ImageUtils";


    export default {
        data() {
            return {
                selectPosition: null,
                pageNum: 1,
                pageSize: 10,
                tableData: [{}],
                total: 0,
                keywords: '',
                channels: [],
                time: '',
                addVisible:false,
                minipro:{},
                merchantList:[],
                userList:[],
                skuList:[],
                loading:false,
            }
        },
        created() {
            this.queryPage()
            this.getMerchantOption()
            this.getTemplateOption()
        },
        methods: {
            //获取商户
            getMerchantOption(){
                getMerchantOption().then(res=>{
                    if(res.code==200){
                        this.merchantList = res.data
                    }
                })
            },
            getTemplateOption(){
                getTemplateInfoOption().then(res=>{
                    if(res.code==200){
                        this.skuList = res.data
                        console.log(this.skuList)
                    }
                })
            },
            //图片判断
            imageUrl(url){
                return getImageUrl(url);
            },
            queryPage(pagenum = 1) {
                let params = {
                    pageNum: pagenum,
                    pageSize: this.pageSize,
                }
                if(this.keywords.length>0){
                    params.keyword = this.keywords
                }
                if(this.time&&this.time.length>0){
                    params.startRegisterTime = this.time[0]
                    params.endRegisterTime = this.time[1]
                }
                getMiniProPage(params).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.list
                        console.log(this.tableData)
                        this.total = res.data.total
                    }
                })
            },
            // 分页改变事件
            current_change(e) {
                this.pageNum = e
                this.queryPage(e)
            },
            // 搜索关键词事件
            search_keywords() {
                this.queryPage()
            },
        //    添加小程序
            addmini(){
                this.addVisible = true
                this.minipro = {}
            },
            close(){
                this.minipro = {}
                this.addVisible = false
            },
            createdTemplate(){

                let _this = this
                if(!this.minipro.miniProName){
                    this.$message.warning('请输入小程序名称')
                    return;
                }
                if(!this.minipro.merchantId){
                    this.$message.warning('请选择企业/组织')
                    return;
                }
                if(!this.minipro.userid){
                    this.$message.warning('请选择创建者')
                    return;
                }
                if(!this.minipro.priceId){
                    this.$message.warning('请选择模板')
                    return;
                }
                this.loading = true
                createTempOrderNoPayment({
                    merchantId:this.minipro.merchantId,
                    miniProName:this.minipro.miniProName,
                    merchantAccountId:this.minipro.userid,
                    priceId:this.minipro.priceId
                }).then(res=>{
                    _this.loading = false
                    // console.log(res)
                    if(res.code==200){
                        _this.$message.success('添加成功')
                        _this.addVisible = false
                        _this.queryPage()
                    }else{
                        _this.$message.error(res.message)
                    }
                })
            },
            //根据
            merchantChange(){
                getMerchantAccountOption({
                    merchantId:this.minipro.merchantId
                }).then(res=>{
                    if(res.code==200){
                        this.userList = res.data
                    }
                })
            },
        },

    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    .table_box {
        padding: 15px;
        margin: auto;
        background: #fff;
    }

    .search_box {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }

    .keyword {
        height: 50px;
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .keyword span {
        width: 80px;
        text-align: center;
    }

    .goods-image-box {
        width: 40px;
        height: 40px;
        align-content: center;
        border-radius: 40px;
        margin-left: 20px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .photoUrl{
        width:40px;
        height: 40px;
        border-radius: 50% 50%;
        vertical-align: middle;
        background: #eee;
        position: relative;
        margin-right: 5px;
        img{
            width: 26px;
            height: 23px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -11.5px;
            margin-left: -13px;
        }
    }
    .tips{
        font-size: 12px;
        color: #999;
        line-height: 15px;
    }
    .preview{
        color: @color-primary;
        cursor: pointer;
        text-align: center;
        display: inline-block;
        box-sizing: border-box;
        border-bottom: 1px solid transparent;
        transition: .15s;
        line-height: 1;
        text-decoration: none;
        &:hover{
            border-bottom-color: @color-primary;
        }
    }
    .formContent{
        /deep/ .el-select{
            width: 100%;
        }
    }
</style>
